/* scrollbar styles */
::-webkit-scrollbar {
  height: 2px;
  width: 6px;
  border-top-right-radius: 12px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

/* phone input package styles */
.react-international-phone-input-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 5fr;
  height: 45px;
}

button.react-international-phone-country-selector-button {
  height: 100%;
  width: 100%;
  background: #f7f7f7;
  border-right: unset !important;
}

.react-international-phone-country-selector {
  height: 100%;
}

input.react-international-phone-input {
  height: 100% !important;
}

.react-international-phone-country-selector-dropdown {
  z-index: 9;
}

/* leaflet map css */
.leaflet-top.leaflet-left {
  width: 100%;
  padding: 0px 10px;
}

.geosearch.leaflet-bar.leaflet-control.leaflet-control-geosearch.leaflet-geosearch-button {
  width: 46%;
  margin: 0px;
  border: unset;
  border-radius: unset;
  position: fixed;
  left: 20px;
  top: 65px;
}

.geosearch.leaflet-bar.leaflet-control.leaflet-control-geosearch.leaflet-geosearch-button
  input.glass {
  width: 100%;
  padding: 16px 15px 16px 20px;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  background: #fff;
  color: #000;
  font-family: "Plus Jakarta Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
}

.geosearch.leaflet-bar.leaflet-control.leaflet-control-geosearch.leaflet-geosearch-button
  .results.active {
  width: 100%;
  background: #fff;
  display: grid;
  gap: 15px;
  padding: 15px 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.geosearch.leaflet-bar.leaflet-control.leaflet-control-geosearch.leaflet-geosearch-button
  .results.active
  > div {
  border-bottom: 1px solid #00000029;
  padding-bottom: 15px;
  color: #585858;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.geosearch.leaflet-bar.leaflet-control.leaflet-control-geosearch.leaflet-geosearch-button
  .results.active
  > div:last-child {
  border-bottom: unset;
}

button.reset {
  display: none;
}

a.leaflet-bar-part.leaflet-bar-part-single {
  display: none;
}

.geosearch.leaflet-bar.leaflet-control.leaflet-control-geosearch.leaflet-geosearch-button
  input.glass:focus-visible {
  outline: unset;
  border-color: #e0e0e0;
}

.leaflet-control-zoom.leaflet-bar.leaflet-control a span {
  color: #000 !important;
}

@media (max-width: 900px) {
  .geosearch.leaflet-bar.leaflet-control.leaflet-control-geosearch.leaflet-geosearch-button {
    width: 100%;
    padding-right: 40px;
  }
  .leaflet-container.leaflet-touch.leaflet-retina.leaflet-fade-anim.leaflet-grab.leaflet-touch-drag.leaflet-touch-zoom {
    height: 340px !important;
  }
  .geosearch.leaflet-bar.leaflet-control.leaflet-control-geosearch.leaflet-geosearch-button {
    top: 405px;
  }

  /* for live */

  .leaflet-container.leaflet-touch.leaflet-fade-anim.leaflet-grab.leaflet-touch-drag.leaflet-touch-zoom {
    height: 340px !important;
  }
}

.leaflet-bar.easy-button-container.leaflet-control
  button.easy-button-button.leaflet-bar-part.leaflet-interactive.default-active
  span.button-state.state-default.default-active
  span {
  display: flex;
  width: 27px;
  height: 29px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 2px;
  border: unset !important;
}

/* styles applied to the google autocomplete predictions list */
.pac-container {
  z-index: 10000 !important;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

/* time picker popup styles (web view) */
.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected {
  background-color: #000;
}

/* time picker styles (mobile view) */
.MuiClockPointer-root,
.MuiClock-pin,
.MuiClockPointer-thumb {
  background-color: #000 !important;
}

/* time picker popup footer buttons (mobile view) */
.MuiDialogActions-root .MuiButtonBase-root {
  color: #000 !important;
}

/* time picker styles (mobile view) */
.MuiClockPointer-thumb {
  border: 16px solid #000 !important;
}

/* tooltip component styles */
.MuiTooltip-tooltip.MuiTooltip-tooltipArrow {
  background-color: #f6f6f6;
  color: black;
  border: 1px solid #e6e6e6;
  margin-top: 5px;
  padding: 10px;
}

.MuiTooltip-arrow::before {
  color: #f6f6f6;
  border: 1px solid #e6e6e6;
}

.MuiTooltip-popper {
  z-index: 1 !important;
}
